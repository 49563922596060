<template>
  <svg width="55px" height="58px" viewBox="0 0 55 58" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: sketchtool 56 (101010) - https://sketch.com -->
    <title>41EB2B8C-6E02-4AFA-B060-18F33E8049A4</title>
    <desc>Created with sketchtool.</desc>
    <g id="Asset-Artboard-Page" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="icons/documents/passport" transform="translate(0.685714, 0.000000)">
            <path d="M26.5,8 C29.500015,8.08235335 32,9.11175482 34,11.0882353 C36,13.0647158 37,15.5558673 37,18.5617647 C37,21.5676621 36,24.0588136 34,26.0352941 C32,28.0117746 29.500015,29 26.5,29 C23.499985,29 21,28.0117746 19,26.0352941 C17,24.0588136 16,21.5676621 16,18.5617647 C16,15.5558673 17,13.0647158 19,11.0882353 C21,9.11175482 23.499985,8.08235335 26.5,8 Z M27.5,31 C33.9035408,31.0989588 39.2105053,32.3359256 43.4210526,34.7109375 C47.6316,37.0859494 49.8245605,40.0546697 50,43.6171875 L50,50 L5,50 L5,43.6171875 C5.17543947,40.0546697 7.3684,37.0859494 11.5789474,34.7109375 C15.7894947,32.3359256 21.0964592,31.0989588 27.5,31 Z" id="" :fill="color" fill-rule="nonzero"></path>
            <g id="icon" transform="translate(20.000000, 28.000000)">
                <rect id="bg" stroke="#979797" fill="#D8D8D8" opacity="0" x="0.5" y="0.5" width="27" height="28"></rect>
                <g id="Group-2" transform="translate(0.000000, 5.000000)">
                    <path d="M6,-3.5 C5.17157288,-3.5 4.5,-2.82842712 4.5,-2 L4.5,22 C4.5,22.8284271 5.17157288,23.5 6,23.5 L22,23.5 C22.8284271,23.5 23.5,22.8284271 23.5,22 L23.5,-2 C23.5,-2.82842712 22.8284271,-3.5 22,-3.5 L6,-3.5 Z" id="bg-copy" stroke="#FFFFFF" :fill="color" transform="translate(14.000000, 10.000000) rotate(90.000000) translate(-14.000000, -10.000000) "></path>
                    <path d="M6.22222222,2.10526316 C6.81481778,2.12177511 7.30864198,2.32817139 7.7037037,2.7244582 C8.09876543,3.12074502 8.2962963,3.62022402 8.2962963,4.22291022 C8.2962963,4.82559641 8.09876543,5.32507542 7.7037037,5.72136223 C7.30864198,6.11764904 6.81481778,6.31578947 6.22222222,6.31578947 C5.62962667,6.31578947 5.13580247,6.11764904 4.74074074,5.72136223 C4.34567901,5.32507542 4.14814815,4.82559641 4.14814815,4.22291022 C4.14814815,3.62022402 4.34567901,3.12074502 4.74074074,2.7244582 C5.13580247,2.32817139 5.62962667,2.12177511 6.22222222,2.10526316 Z M6.48148148,6.84210526 C7.73583762,6.86129396 8.77539115,7.10114901 9.60017327,7.56167763 C10.4249554,8.02220625 10.854523,8.59785839 10.8888889,9.28865132 L10.8888889,10.5263158 L2.07407407,10.5263158 L2.07407407,9.28865132 C2.10844,8.59785839 2.53800757,8.02220625 3.36278969,7.56167763 C4.18757181,7.10114901 5.22712534,6.86129396 6.48148148,6.84210526 Z" id="" fill="#FFFFFF" fill-rule="nonzero"></path>
                    <rect id="Rectangle-Copy-8" fill="#FFFFFF" x="2.07407407" y="12.6315789" width="23.8518519" height="1.57894737"></rect>
                    <rect id="Rectangle-Copy-11" fill="#FFFFFF" x="2.07407407" y="16.3157895" width="23.8518519" height="1.57894737"></rect>
                </g>
            </g>
        </g>
    </g>
</svg>
</template>

<script>
export default {
    props:{
        color: {
            type: String,
            // default: "#005D7B"
        }
    }
}
</script>

<style>

</style>